import React from 'react';
import ReactDOM from 'react-dom';
import { MantineProvider, NormalizeCSS } from '@mantine/core';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4';  

// Initialize Google Analytics
ReactGA.initialize('G-Y22JPGGN1K');

// Add config command for Google Ads
ReactGA.gtag('config', 'AW-11316402859');

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider>
      <NormalizeCSS />
      <App />
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
