import React from 'react';
import { SimpleGrid, Container } from '@mantine/core';
import TestimonialBlurb from './TestimonialBlurb'; // Path to your TestimonialBlurb component
import jerryPic from './assets/img/vlad.jpg';
import georgePic from './assets/img/andreeas.jpg';
import elainePic from './assets/img/luis.jpg';

const Testimonials = () => {
  return (
    <Container size="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <SimpleGrid cols={3} spacing="xl" breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        {/* 
        <TestimonialBlurb
          name="What's the deal with strings? Can't you just use Stringbot?"
          email="Jerry Seinfeld"
          job="Comedian"
          avatar={jerryPic}
        />
        <TestimonialBlurb
          name="I proclaim this the summer of Stringbot."
          email="George Costanza"
          job="The Human Fund"
          avatar={georgePic}
        />
        <TestimonialBlurb
          name="Maybe the Stringbot ate your content."
          email="Elaine Benes"
          job="Newspaper Editor"
          avatar={elainePic}
        />*/}

        
        <TestimonialBlurb
          name="I support a lot of copy across my product. Stringbot has helped me scale by making brainstorming process much, much easier."
          email="Vlad Shulman"
          job="Founder"
          avatar={jerryPic}
        />
        <TestimonialBlurb
          name="English isn't my first language, yet I always end up writing the strings for my site. Stringbot has my team thinking I hired a UX writer."
          email="Andreeas Trattner"
          job="Software Developer"
          avatar={georgePic}
        />
        <TestimonialBlurb
          name="We're a small team and I'm often left with the job of writing the content. Stringbot has made this super easy, our content is already performing better on buttons."
          email="Luis Juarez"
          job="Planner"
          avatar={elainePic}
        />

      </SimpleGrid>
    </Container>
  );
};

export default Testimonials;
