import React, { useState, useEffect } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconEdit, IconCopy, IconDeviceFloppy, IconCirclePlus, IconExclamationCircle } from '@tabler/icons-react';
import commonStyles from './css/CommonStyles.js';


function SaveButton({ timeout, onClick, saveStatus, setSaveStatus }) {
  
  const [disabled, setDisabled] = useState(false);

  const { classes } = commonStyles();

  const handleClick = () => {
    console.log("Save Button: Handle click - ", saveStatus);
    onClick();
  };

  useEffect(() => {
    if (saveStatus === 'saved' || saveStatus === 'error') {
      setDisabled(true);
      
      const timer = setTimeout(() => {
        setDisabled(false);
        setSaveStatus("unsaved");
      }, timeout);
  
      // Clear the timer when the component unmounts
      return () => {    
        clearTimeout(timer);
      };
    }
  }, [saveStatus, timeout]);

  return (
    <Tooltip 
      label={(saveStatus === 'saved' && disabled) ? 'Saved' : ((saveStatus === 'error' && disabled) ? 'Error' : 'Save')} 
      withArrow position="right">
      <ActionIcon 
          className={classes.buttonColor}
          style={{
            color: (saveStatus === 'saved' && disabled) ? 'green' : (saveStatus === 'error' && disabled) ? 'red' : undefined
          }}
          onClick={handleClick}
      >
        {saveStatus === 'saved' && disabled ? (
          <IconCheck size="1rem" />
        ) : (saveStatus === 'error' && disabled) ? (
          <IconExclamationCircle size="1rem" />
        ) : (
          <IconCirclePlus size="1rem" />
        )}
      </ActionIcon>
    </Tooltip>
  );
}

export default SaveButton;
