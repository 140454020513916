import React, { useState, useEffect, useMemo, useRef  } from 'react';
import { Pagination, Select, Center, Loader, Grid, Tooltip, Container, Text, Stack } from '@mantine/core';
import IdeaCopyButton from './IdeaCopyButton';
import IdeaPaper from './IdeaPaper';
import SearchBar from './SearchBar';
import EmptyLibraryState from './EmptyLibraryState'; 
import { useDebouncedValue } from '@mantine/hooks';
import { IconBooks } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';


function LibraryPage({setActiveLink, handleJWTExpiry}) {
  const [strings, setStrings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 325);
  const [currentPage, setCurrentPage] = useState(1);
  const jwtExpiryHandledRef = useRef(false);
  const [selectedTag, setSelectedTag] = useState('');
  const itemsPerPage = 7;
  const [hasError, setHasError] = useState(false);

  const [tagData, setTagData] = useState([
    { value: 'react', label: 'React' },
    { value: 'ng', label: 'Angular' },
    { value: 'svelte', label: 'Svelte' },
    { value: 'vue', label: 'Vue' },
    { value: 'riot', label: 'Riot' },
    { value: 'next', label: 'Next.js' },
    { value: 'blitz', label: 'Blitz.js' },
  ]);

  //when search changes, reset page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchQuery, selectedTag]);
  


  useEffect(() => {
      if (jwtExpiryHandledRef.current) {
        return; // Exit if JWT has already expired
      }
      async function loadTags() {
          try {
              console.log('Tags: attempting to load tags');
              const response = await fetch('https://stringbotwebapp.azurewebsites.net/tags', {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              });
              
              if (!response.ok) {
                  if (response.status === 401) {
                       throw new Error('JWT expired');
                  }
              }
              
              const data = await response.json();
              
              console.log('Tags: got something', data);
              
              if (data) {
                console.log('Tags: got real tag data');
                // Map the response data to the desired format
                setTagData(data.map(item => ({ value: item.tag, label: item.tag })));
              }
          } catch (error) {
              if (error.message === 'JWT expired' && !jwtExpiryHandledRef.current) {
                jwtExpiryHandledRef.current = true;
                handleJWTExpiry();
              } else {
                console.error('Error fetching tags:', error);
                setHasError(true);
              }
          }
      }
      loadTags();
  }, [handleJWTExpiry]);
  

  useEffect(() => {
    if (jwtExpiryHandledRef.current) {
      return; // Exit if JWT has already expired
    }
    fetch('https://stringbotwebapp.azurewebsites.net/strings', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'), // Send the token here
      },
    })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('JWT expired');
        }
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
        //console.log('Strings: strings loaded', data);
        setStrings(data);
        setLoading(false); // Set loading to false when fetch is completed
    })      
    .catch((error) => {
      if (error.message === 'JWT expired' && jwtExpiryHandledRef.current) {
        jwtExpiryHandledRef.current = true;
      } else {
        console.error('Error fetching strings:', error);
        setHasError(true);
      }
      setLoading(false);
    });
  }, [handleJWTExpiry]);

  // Callback function to update the strings state
  const updateString = (editedString, editedTags, _id) => {
    setStrings((prevStrings) =>
      prevStrings.map((stringItem) =>
        stringItem._id === _id ? { ...stringItem, string: editedString, tags: editedTags } : stringItem
      )
    );
  };

  // Use useMemo to memoize the filteredStrings array and recalculate it only when the dependencies change
  const filteredStrings = useMemo(() => {
    return strings.filter((string) => {

      const matchesSearch = string.string.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
      const matchesTag = !selectedTag || string.tags.includes(selectedTag);

      return matchesSearch && matchesTag;
      //return string.string.toLowerCase().includes(debouncedSearchQuery.toLowerCase());
    });
  }, [strings, debouncedSearchQuery, selectedTag]);

  // Calculate the total number of pages based on the total number of items and the items per page
  const totalPages = Math.ceil(filteredStrings.length / itemsPerPage);

  // Get the current page's items based on the itemsPerPage and currentPage
  const currentPageItems = filteredStrings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handleDeleteString = async (deletedId) => {
    // Update the strings state by removing the deleted string using its _id
    setStrings((prevStrings) => prevStrings.filter((string) => string._id !== deletedId));
  };

  //const rows = Array.isArray(strings) ? strings.map((item) => (
   // {
      /*{filteredStrings.map((item) => (
        <div key={item._id}>
          <div style={{
            wordWrap: 'break-word',
          }}>
            <IdeaPaper
              _id={item._id}
              mode="library"
              string={item.string}
              title="Idea #1"
              onDelete={handleDeleteString}>
            </IdeaPaper>
          </div>
          ))}*/
      {/*<td>{formatDate(item.date)}</td>*/}
   // </div>
  //)) : null

  return (
    <>
        <SearchBar setSearchQuery={setSearchQuery} searchQuery={searchQuery} tagData={tagData} setSelectedTag={setSelectedTag} selectedTag={selectedTag}>
          Find, tweak, and zap away your snippets.
        </SearchBar>
      {loading ? ( // Render the spinner when loading is true
        <Center>
            <Loader variant="dots" />
        </Center>
      ) : hasError ? (
        <Container size="sm" style={{ textAlign: 'center', marginTop: '3rem' }}>
          <Text size="xl">Oops!</Text>
          <Text size="md" style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
            Something went wrong while fetching your strings, try again by refreshing.
          </Text>
        </Container>
      ) : (strings.length === 0) ? (
        <EmptyLibraryState setActiveLink={setActiveLink} />
      ) : (filteredStrings.length === 0) ? (
        <Container size="sm" style={{ textAlign: 'center', marginTop: '3rem' }}>
          <Text size="xl">No luck</Text>
          <Text size="md" style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
            We couldn't find anything for your search. Try a different query or tag. 
          </Text>
        </Container>
      ) : (
        <Stack spacing="lg">
          {currentPageItems.map((item) => (
            <div key={item._id}>
              <div style={{
                wordWrap: 'break-word',
              }}>
                <IdeaPaper
                  _id={item._id}
                  mode="library"
                  string={item.string}
                  title="Idea #1"
                  onDelete={handleDeleteString}
                  updateString={updateString}
                  setTagData={setTagData}
                  tagData={item.tags}
                  allTags={tagData}
                  userPrompt={item.userPrompt}
                  handleJWTExpiry={handleJWTExpiry}
                  >
                </IdeaPaper>
              </div>
            </div>
          ))}
          <Center>
            <Pagination
              total={totalPages}
              value={currentPage}
              onChange={handlePageChange}
            />
          </Center>
        </Stack>
      )}
    </>
  );
}

export default LibraryPage;
