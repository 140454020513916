import React from 'react';
import { Title, Group, Avatar, Paper, Text, Container, Button, createStyles, useMantineTheme  } from '@mantine/core';


const useStyles = createStyles((theme) => ({
    accentColor: {
        //color: theme.colors.gray[4],
        color: theme.colors.gray[7],
      },

      mainColor: {
        color: theme.colors.gray[7],
        //color: theme.colors.gray[7],
      },
      title: {
        //fontFamily: "Brush Script MT",
        fontFamily: "Palatino, Palatino Linotype, serif",
      },
      avatarOverlay: {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            //background: 'rgba(255, 165, 0, 0.3)', // This is a partially transparent orange
            borderRadius: '50%', // Assuming the Avatar is circular, this will ensure the overlay is too
        }
    },
  }));

function HeaderLogo({ image, title, titleAccent }) {
    const { classes, cx } = useStyles();
    return (
            <Group spacing={10}>
                <a href="/" className={classes.avatarOverlay} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Avatar src={image} alt={title} radius="xl" />
                </a>
                <Title order={3} className={`${classes.mainColor} ${classes.title}`}  weight={575}>
                    {title}
                    <a className={classes.accentColor}>{titleAccent}</a>
                </Title>           
            </Group>
    );
}

export default HeaderLogo;
