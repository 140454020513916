import { createStyles, rem } from '@mantine/core';

const commonStyles  = createStyles((theme) => ({
    dimmedText: {
        color: theme.colors.orange[7]
    },
    buttonColor: {
        color: theme.colors.orange[5]
    },
    buttonSuccess: {
        color: 'green', // or use a specific shade from your theme
    },
    buttonFail: {
        color: 'red', // or use a specific shade from your theme
    },
    paperColor: {
        backgroundColor: theme.colors.white,
        color: theme.colors.black
    },
    panel: {
        //backgroundColor: theme.colors.white,
        backgroundColor: '#fcf8f2'
    },
    control: {
        //backgroundColor: theme.colors.white,
        backgroundColor:  '#fcf8f2'
    },
    appContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    contentContainer: {
        flex: 1,
    },
    landingPageContainer: {
        background: 'linear-gradient(to bottom, #fcfcfa, #faedcf, #fcfcfa)',
        width: '100%',
    }
    
}));

export default commonStyles;
