import React, { useState } from 'react';
import { Stack, Alert, Container, TextInput, Button, Paper, Title, Text } from '@mantine/core';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import {getHotkeyHandler } from '@mantine/hooks';
import login from './login'; 
import ReactGA from 'react-ga4';


function AccountPage({setActiveLink, setIsUserLoggedIn, setShowConfetti, setUserName}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({ success: null, message: '' });

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [nameError, setNameError] = useState(null);

  const handleLoginClick = () => {
    setActiveLink('/login'); 
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

// Function to validate password is at least 8 characters long, 
// contains at least 1 letter and 1 number, and can contain any other character
const validatePassword = (password) => {
  // Regular expression for password validation
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
  return passwordRegex.test(password);
};



// Function to validate name contains only letters and has length between 1 and 15 characters
// Allow letters from the start.
// Allow single spaces between letters.
// Limit the length between 1 and 15 characters.
const validateName = (name) => {
  const nameRegex = /^(?=.{1,15}$)[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
  return nameRegex.test(name);
};



const handleEmailBlur = () => {
  if (!validateEmail(email) && email != '') {
    setEmailError('Enter a valid email');
  } else {
    setEmailError(null);
  }
};

const handlePasswordBlur = () => {
  if (!validatePassword(password) && password != '') {
    setPasswordError("Make sure your password has 8 characters, at least 1 letter, and 1 number.");
  } else {
    setPasswordError(null);
  }
};

const handleNameBlur = () => {
  if (!validateName(name) && name != '') {
    //
    setNameError('Use a name with only letters and keep the length between 1 and 15.');
  } else {
    setNameError(null);
  }
};


  
  const handleRegister = () => {
    setLoading(true);

    ReactGA.event({
      category: 'Registration',
      action: 'AccountPage: Create account click'
    });

    const userData = {
      name,
      email,
      password,
    };

    // Validate name
    if (!validateName(name)) {
      setResponse({ success: false, message: 'Please enter a name that contains only letters and has a length between 1 and 15 characters' });
      setLoading(false);
      return;
    }

    // Validate the email address
    if (!validateEmail(email)) {
      setResponse({ success: false, message: 'Please enter a valid email address' });
      setLoading(false);
      return;
    }

    // Validate password 
    if (!validatePassword(password)) {
      setResponse({ success: false, message: 'Password must be at least 8 characters long, contain at least 1 letter and 1 number' });
      setLoading(false);
      return;
    }


    // Make a POST request to the backend
    fetch('https://stringbotwebapp.azurewebsites.net/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        // Check if status code is 409 (Conflict)
        if (response.status === 409) {
          throw new Error('An account with that email already exists');
        }
        return response.json();  // Continue with the usual JSON parsing
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setResponse({ success: true, message: 'Success!' });
        login(email, password) // Use the login function to log in the user
          .then((loginData) => {
            if (loginData.success) {
              setShowConfetti(true);
              localStorage.setItem('token', loginData.token); // Set the JWT token in localStorage
              localStorage.setItem('userName', data.user.name);
              setUserName(data.user.name);
              setIsUserLoggedIn(true); // Update state here
              console.log('Logged in user after registration:', data.user.name);
              // Show notification
              notifications.show({
                title: `Welcome, ${data.user.name}!`,
                message: 'You\'re logged in.',
                color: 'green',
                icon: <IconCheck size="1rem" />,
              });

              ReactGA.event({
                category: 'Registration',
                action: 'AccountPage: Account created'
              });

              // Also log conversion event for google ads. 
              ReactGA.gtag('event', 'conversion', {'send_to': 'AW-11316402859/nibrCNSg8_AYEKu1ipQq'});
            }
          });
      })
      .catch((error) => {
        console.error('Error registering user:', error);
        setResponse({ success: false, message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container 
      size="xs" 
      style={{ paddingTop: '3rem' }}
      onKeyDown={getHotkeyHandler([
        ['Enter', handleRegister],
      ])}
    >
      <Paper 
          shadow="xs" radius="lg" 
          p="lg" withBorder 
          style={{ marginBottom: '2rem', marginTop: '2rem' }}
      >
        <Title order={2} align="center" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          Writing help is one step away
        </Title>
        {/*<Text size="md"  c="dimmed" align="center" style={{ marginBottom: '2rem' }}>
          Start creating for free
    </Text>*/}
        <Stack>
          <TextInput
            label="First name"
            value={name}
            size="md"
            onChange={(event) => setName(event.currentTarget.value)}
            
            onBlur={handleNameBlur}
            error={nameError}
            //withErrorStyles={false}
            style={{ marginBottom: '0.5rem' }}
            autoFocus 
          />
          <TextInput
            type="email"
            size="md"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value.toLowerCase())}
            label="Email"
            onBlur={handleEmailBlur}
            error={emailError}
            //withErrorStyles={false}
            style={{ marginBottom: '0.5rem' }}
          />
          <TextInput
            size="md"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            label="Password"
            placeholder="8+ characters with letters and numbers"
            onBlur={handlePasswordBlur}
            error={passwordError}
            //withErrorStyles={false}
            style={{ marginBottom: '0.5rem' }}
          />
          <Button onClick={handleRegister} loading={loading}>
            Continue
          </Button>
        </Stack>
        {response.message && (
          <Alert
            title={response.message}
            /*title={response.success ? 'Registration Successful!' : 'Registration Failed!'}*/
            color={response.success ? 'green' : 'red'}
            icon={response.success ? <IconCheck size="1rem" /> : <IconAlertCircle size="1rem" />}
            shadow="xs"
            style={{ marginTop: '1rem' }}
          >
            
          </Alert>
        )}
        <Text align="center" style={{ marginTop: '1.5rem' }}>
          Already have an account? <a href="#" onClick={handleLoginClick}> Log in</a>
        </Text>
      </Paper>
    </Container>
  );
}

export default AccountPage;
