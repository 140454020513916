import React from 'react';
import { Accordion, keyframes, MediaQuery, createStyles, Blockquote, Flex, ThemeIcon, List, Stack, Container, Button, Text, Title, Paper, Col, Grid, Card, useMantineTheme, rem } from '@mantine/core';
import { IconCashBanknote, IconBuildingCommunity, IconStars, IconCircleCheck, IconRocket, IconBulb, IconGlobe, IconHeartHandshake, IconQuestionMark } from '@tabler/icons-react';
import demoGif from './assets/stringbotrecording4.gif';
import demoMobileGif from './assets/stringbotlandingmobile3.gif';
import LandingPageFeatures from './LandingPageFeatures.js';
import Testimonials from './Testimonials.js';
import ReactGA from 'react-ga4';

// Look at landing page here and adopt styling https://ui.mantine.dev/category/hero

export const bounce = keyframes({
  'from, 20%, 53%, 80%, to': { transform: 'translate3d(0, 0, 0)' },
  '40%, 43%': { transform: 'translate3d(0, -5px, 0)' }, // Reduced from -30px
  '70%': { transform: 'translate3d(0, -3px, 0)' },       // Reduced from -15px
  '90%': { transform: 'translate3d(0, -2px, 0)' },       // Reduced from -4px
});


const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(60),
    fontWeight: 900,
    color: theme.colors.black,
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(50),
    },
  },
  underline: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      height: '6px',
      width: '100%',
      backgroundColor: theme.fn.primaryColor(),
      bottom: '-1px',
      left: 0,
    },
  },
  ctaButton:{
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.15)', // adjust as needed
    animation: `${bounce} 3s  infinite`,
    '&:hover': {
      animation: `${bounce} 0.8s  infinite`, // apply the bounce animation on hover
    },
  },
  gradientBackground: {
    background: 'linear-gradient(to bottom, #ffffff, #edd498)',
    width: '100%',
  },
  description: {
    fontSize: rem(25),
    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(18),
    },
    maxWidth: 600,
    margin: 'auto',
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },
  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));


function LandingPage({setActiveLink}) {
  const theme = useMantineTheme();
  const { classes } = useStyles();

    const handleSignUpClick = () => {
        setActiveLink('/account'); 
        // Track sign up click gtag conversion
        ReactGA.gtag('event', 'conversion', {'send_to': 'AW-11316402859/tKmnCLux8O8YEKu1ipQq'});

        // Track google analytics event
        ReactGA.event({
          category: 'Conversion',
          action: 'LandingPage: Sign up button click',
          label: 'AW-11316402859/tKmnCLux8O8YEKu1ipQq',
          value: 1
        });
    };



    const handleDemoClick = () => {
        setActiveLink('/demo'); 
    };

//Landing page elements
// 1. Hero section
// 2. Call to action
// 3. Demo video
// 4. Features/benefits
// 5. Social proof
// 6. Contact form




  return (
    <Container size="md" style={{ marginTop: '2rem' }} >
      <Title order={1} className={classes.title} ta="center" mt="sm">
        <div>Draft great UX copy. <span className={classes.underline}>Fast.</span></div> 
      </Title>

 


      <Text c={theme.colors.gray[7]} className={classes.description} ta="center" mt="md">
        Say goodbye to UX writer's block. Go from messy descriptions to great ideas in seconds.
      </Text>


      
      
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing.md, marginBottom: '2rem' }}>
        <Button 
              style={{ marginTop: '1rem', marginRight: '0.5rem'}} 
              size="lg" 
              variant="gradient"
              radius="xl"
              onClick={handleSignUpClick}
              className={classes.ctaButton}
          >
              Start creating for free
        </Button>
        
      </div>


      <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
        <Paper withBorder shadow="md"  >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={demoGif} style={{ width: '100%' }} alt="Stringbot usage video" />
        </div>
        </Paper>
      </MediaQuery>
      <MediaQuery largerThan="xs" styles={{ display: 'none' }}>
        <Paper withBorder shadow="md"  >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={demoMobileGif} style={{ width: '100%' }} alt="Stringbot usage video" />
        </div>
        </Paper>
      </MediaQuery>

      <LandingPageFeatures></LandingPageFeatures>

      <Title order={1} className={classes.title} ta="center" mt="sm">
        Join the club
      </Title>
      <Text c={theme.colors.gray[7]} className={classes.description} ta="center" mt="md">
        Content designers, UX designers, product managers, and software engineers are using Stringbot to brainstorm their UX copy.  
      </Text>

      <Testimonials
      >
      </Testimonials>


      <Title order={1} className={classes.title} ta="center" mt="sm">
        FAQs
      </Title>
      <Text c={theme.colors.gray[7]} className={classes.description} ta="center" mt="md">
      
      </Text>



      <Accordion  
      //variant="subtle" 
      defaultValue="customization" 
      styles={{ marginBottom: '1rem' }}
    >
            <Accordion.Item value="none3">
              <Accordion.Control icon={<IconBuildingCommunity size={rem(20)} color="gray" />}>
                Why did you build Stringbot?
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ marginBottom: '1rem' }}>
                  We found from years working on software products that writing great UX copy was important, yet difficult. Getting started was the hardest part, so we wanted to make it easier. Our mission is to make the lives of those writing UX copy easier, which we believe will lead to better usability of products across the world. 
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="none2">
              <Accordion.Control icon={<IconCashBanknote size={rem(20)} color="gray" />}>
                Is Stringbot free?
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ marginBottom: '1rem' }}>
                  Stringbot is free, but limits the number of UX copy ideas you can create. As the product evolves, we may add paid versions with more features and a higher idea generation limit. 
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="none">
              <Accordion.Control icon={<IconStars size={rem(20)} color="gray" />}>How does Stringbot work?</Accordion.Control>
              <Accordion.Panel>                
                <Text style={{ marginBottom: '1rem' }}>
                  Stringbot uses proprietary process that leverages GPT-4 from OpenAI to generate UX copy ideas. Your inputs and outputs are private and are not used in the training of any AI models. 
                </Text>
                <div style={{ marginBottom: '1rem', marginRight: '1rem', marginLeft: '1rem' }}>

                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="none5">
              <Accordion.Control icon={<IconHeartHandshake size={rem(20)} color="gray" />}>Is Stringbot replacing UX writers?</Accordion.Control>
              <Accordion.Panel>                
                <Text style={{ marginBottom: '1rem' }}>
                <em>No. </em>Stringbot wants product teams be more efficient and to improve the UX copy across software products. While a great brainstorming tool, amazing UX copy takes creativity, curation, and editing by a human expert. 
                </Text>
                <div style={{ marginBottom: '1rem', marginRight: '1rem', marginLeft: '1rem' }}>

                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="none9">
              <Accordion.Control icon={<IconQuestionMark size={rem(20)} color="gray" />}>What is a string? What is UX Copy?</Accordion.Control>
              <Accordion.Panel>                
                <Text style={{ marginBottom: '1rem' }}>
                  Strings, UI/UX copy, microcopy, or product copy refer to the text users see throughout a product (typically software). It helps them figure out how to do things. This includes error messages, labels, instructions, and more. It is a growing area of design, and key to how a person navigates a product. 
                </Text>
                <div style={{ marginBottom: '1rem', marginRight: '1rem', marginLeft: '1rem' }}>

                </div>
              </Accordion.Panel>
            </Accordion.Item>
    </Accordion>



      <Title style={{ marginTop: '2rem' }} order={1} className={classes.title} ta="center" mt="sm">
        Questions?
      </Title>

      <Text c={theme.colors.gray[7]} className={classes.description} ta="center" mt="md">
        Contact us at <a href="mailto:info@stringbot.ai">info@stringbot.ai</a>
      </Text>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing.md, marginBottom: '2rem' }}>
        <Button 
              style={{ marginTop: '1rem', marginRight: '0.5rem'}} 
              size="lg" 
              variant="gradient"
              radius="xl"
              onClick={handleSignUpClick}
              className={classes.ctaButton}
          >
              Start creating for free
        </Button>
        
      </div>
     

    </Container>
  );
}

export default LandingPage;
