import React from 'react';
import { Container, Group, Text, Anchor, Stack, createStyles, Center, getStylesRef } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    footer: {
      //borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.orange[2]}`,  // Adapted the border color from the given styles
      //marginTop: "120px",
      //backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.orange[1],
      //padding: `${theme.spacing.xl}px ${theme.spacing.md}px`,

      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    inner: {
      /*display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing.md,
  
      '@media (max-width: 600px)': {  // Assuming mantine-breakpoint-sm is 600px. Adjust accordingly.
        flexDirection: 'column',
      }*/
      flex: 1,
    },
    link: {
      marginRight: theme.spacing.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
    },
    badge: {
      marginLeft: theme.spacing.xs,
      backgroundColor: theme.colors.indigo[5],
      color: theme.colors.indigo[0],
    },
    textColor: {
      color: theme.colors.gray[6],
      textDecoration: 'none',
    },
}));

function Footer() {
  const { classes } = useStyles();

  return (
    <Container size="md" > 
      <div >
        <Stack style={{ marginBottom: '2rem' }}>
          <Center>
            <Group>
              {/*<Anchor className={classes.link} href="/privacy">
                Privacy Policy
              </Anchor>*/}
              <Text className={classes.textColor} size="sm">© {new Date().getFullYear()} TinyLoon LLC</Text>
              <Text className={classes.textColor} size="sm">
                <a style={{ color: 'inherit', textDecoration: 'none' }} href="mailto:feedback@stringbot.ai">Contact us</a>
              </Text>
            </Group>
          </Center>
        </Stack>
      </div>
    </Container>
  );
}

export default Footer;
