import React from 'react';
import {Popover, ActionIcon, Center, Chip, Group, Text, Stack, Button, Avatar, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  accentColor: {
      //color: theme.colors.gray[4],
      color: theme.colors.gray[7],
    },

    mainColor: {
      color: theme.colors.gray[7],
      //color: theme.colors.gray[7],
    },

    avatarOverlaySelected: {
        position: 'relative',
        backgroundColor: theme.colors.orange[4],
        borderRadius: '50%',
        
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            //background: 'rgba(255, 165, 0, 0.3)', // This is a partially transparent orange
            borderRadius: '50%', // Assuming the Avatar is circular, this will ensure the overlay is too
        }
    },

    avatarOverlayDisabled: {
      position: 'relative',
      backgroundColor: theme.colors.orange[0],
      borderRadius: '50%',
      
      '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          //background: 'rgba(255, 165, 0, 0.3)', // This is a partially transparent orange
          borderRadius: '50%', // Assuming the Avatar is circular, this will ensure the overlay is too
      },

      '&:hover': {
        backgroundColor: theme.colors.orange[0],
    }
    },


    avatarOverlay: {
      position: 'relative',
      
      
      '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          //background: 'rgba(255, 165, 0, 0.3)', // This is a partially transparent orange
          borderRadius: '50%', // Assuming the Avatar is circular, this will ensure the overlay is too
      },
      '&:hover': {
        backgroundColor: theme.colors.orange[2],
      }
}

}));

function AvatarChip({ image, label, color, selected, value, setSelectedTone, setToneValue, isSending, tooltip, ...others }) {
    const { classes, cx } = useStyles();
    const [opened, { close, open }] = useDisclosure(false);

    

    const handleClick = (e) => {
      e.stopPropagation();

      if (!isSending) {
        console.log("AVATARCHIP: trying to set tone: " + label)
        setSelectedTone(value);      // set selected tone
        setToneValue(label);        // set the tone value (using label here, adjust if needed)
      }
    };

    return (
      
      <Stack onClick={handleClick} spacing={10}>
        <Center>
        <Popover withinPortal={true} width="220" height="200" position="bottom" withArrow shadow="md" opened={opened}>
          <Popover.Target>
            <ActionIcon onMouseEnter={!isSending ? open : close} onMouseLeave={close} loading={selected == value && isSending} loaderProps={{variant: "dots"}}  radius="xl" variant={selected == value ? "gradient" : "outline"} size="xl"  className={isSending ? classes.avatarOverlayDisabled : classes.avatarOverlay} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Avatar size="md" src={image} radius="xl" />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown style={{ pointerEvents: 'none' }}>
            <Text size="sm">{tooltip}</Text>
          </Popover.Dropdown>
        </Popover>
          
        
        </Center>
        <Center>
          <Text size="sm" color={isSending ? "gray" : selected == value ? "orange" : "black"} >
              {label}
          </Text>   
        </Center>      
      </Stack>
      
  );
}

export default React.memo(AvatarChip);
