import React, { useState, useEffect } from 'react';
import { Input, Select, Space } from "@mantine/core";
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from "@tabler/icons-react";

const SearchBar = ({ setSearchQuery, searchQuery, tagData, setSelectedTag, selectedTag }) => {


  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);

    const [debouncedQuery] = useDebouncedValue(localSearchQuery, 300);
    useEffect(() => {
        setSearchQuery(debouncedQuery);
    }, [debouncedQuery, setSearchQuery]);
    
    const handleSearchChange = (e) => {
        setLocalSearchQuery(e.target.value);
    };

  return (
    <>
        <Space h="md" />
          <Input
            icon={<IconSearch size={18} />}
            size="md"
            placeholder="Search"
            radius="xl"
            onChange={handleSearchChange} // Call the handleChange function when the input value changes
            rightSectionWidth="120"
            rightSection={
              <Select
                size="md"
                radius="sm"
                placeholder="Tags"
                data={[
                  { value: '', label: 'All tags' },
                  ...tagData
                ]}
                value={selectedTag}
                onChange={(tags) => setSelectedTag(tags)}
              />
            }
          
          />
        <Space h="md" />
        
    </>
  );
};

export default SearchBar;