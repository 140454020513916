import React from 'react';
import {
  Container,
  createStyles,
  Header,
  MantineLogo,
  Group,
  Burger,
  rem,
  Button,
  Transition,
  Paper,
  Text,
  Menu,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import HeaderLogo from './HeaderLogo';
import { IconUser, IconChevronDown } from '@tabler/icons-react';
import ReactGA from 'react-ga4';


const HEADER_HEIGHT = rem(60);

// TODO still need to fix mobile header options. They work in "responsive header" here. Copy this. 
// https://ui.mantine.dev/category/headers

const useStyles = createStyles((theme) => ({
  
  root: {
    position: 'relative',
    zIndex: 100,
  },
  
  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 99,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: theme.colors.orange[0],

    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  headerbackground: {
    //backgroundColor: 'rgba(255, 255, 255, 0.9)',  // White with 90% opacity
    //backgroundColor: theme.colors.orange[0],
    //backgroundColor: "#fffcf7",
    backgroundColor: "#fcfcfa",
    //borderBottom: 'none',
    borderBottom: `0.0625rem solid  #fcfcfa`,
    //boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', 
    
    //sticky header
    //position: 'sticky',
    //top: 0,
    zIndex: 1000,  // Higher than other elements on the page
    //opacity: 0.9,
    //backdropFilter: 'blur(3px)',  // Adjust the pixel value for more or less blur
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.black,
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    //color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.white,
    color: theme.colors.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    [theme.fn.largerThan('xs')]: {
      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
      },
    },

   [theme.fn.smallerThan('xs')]: {
      borderRadius: 0,
      width: '100%',
      //padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.colors.orange[3],
      //backgroundColor: theme.fn.variant({ variant: 'dark', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));


  /*linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        //color: theme.primaryColor,
        color: theme.colors.white,
      }).background,
      //color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      color: theme.colors.white,
    },
  },
}));*/


function HeaderSimple({ links, activeLink, setActiveLink, isUserLoggedIn, handleLogout, userName }) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const ref = useClickOutside(close); 
  const theme = useMantineTheme(); 

  const handleLinkClick = (link) => {
    console.log('hi ' + link.link);

    ReactGA.event({
      category: 'Header',
      action: 'Header: ' + link.link + ' click'
    });
    
    setActiveLink(link.link);
    toggle();

    if (link.link === '/logout') {
      handleLogout();
    }
  };

  const handleLogoClick = () => {
    setActiveLink('/');
  };

  const items = links.map((link) => {
    // Skip "Get started" button if the user isn't logged in
    if (!isUserLoggedIn && link.label === 'Get started') {
      return null;
    }

    const label = link.label === 'Log in' && isUserLoggedIn ? 'Log out' : link.label;
    //const handleClick = label === 'Log out' ? handleLogout : (event) => {
    const handleClick = (event) => {
      event.preventDefault();
      handleLinkClick(link);
    };

    
    if (label === 'Log out') {
 //     if (theme.fn.largerThan('xs')) {  // For larger screens
        return (
          <Menu key={label} trigger="click" transitionProps={{ exitDuration: 0 }} withinPortal>
            <Menu.Target>
              <Button
                href={link.link}
                className={cx(classes.link, { [classes.linkActive]: activeLink === link.link })}
                onClick={(event) => event.preventDefault()}
                variant="subtle"
              >
                <IconUser color="black" size="md" />
                <Text color="black" style={{ marginRight: 6 }}>
                  {userName || 'User'}
                </Text>
                <IconChevronDown color="black" size={30} stroke={1.5} />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={handleLogout}>Log out</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        );
//      } else {  // For smaller screens
//        return <Button>HIFHDISFHIAUFHIDLFH</Button>;  // You can put what you want to display on smaller screens here
//      }
    }
    


    <Button
        key={label}
        href={link.link}
        className={cx(classes.link, { [classes.linkActive]: activeLink === link.link })}
        onClick={handleClick}
        disabled={!isUserLoggedIn && label === "Library" ? true : false}
        variant="subtle"
      >
      </Button>

    return (
      <Button
        key={label}
        href={link.link}
        className={cx(classes.link, { [classes.linkActive]: activeLink === link.link })}
        onClick={handleClick}
        disabled={!isUserLoggedIn && label === "Library" ? true : false}
        variant="subtle"
      >
        <Text fw={525} size="sm" color="black">{label}</Text>
      </Button>
    );
  });

  return (
    <Header height={60} mb={10} ref={ref} className={classes.headerbackground}>
      <Container size="md" className={classes.header}>
        <HeaderLogo
          image="yarn_logo_orange.png"
          title="String"
          titleAccent="bot"
          onClick={handleLogoClick}
        ></HeaderLogo>
        <Group spacing={5} className={classes.links}>
          {items.filter(Boolean)}
        </Group>
        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" 
          
        />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items.filter(Boolean)}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
    
  );
}

export default HeaderSimple;
