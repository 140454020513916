import React from 'react';
import { Container, Text, Button } from '@mantine/core';

const EmptyLibraryState = ({ setActiveLink }) => {

    const handleCreateClick = () => {
        setActiveLink('/create'); 
    };


  return (
    <Container size="sm" style={{ textAlign: 'center', marginTop: '3rem' }}>
      <Text size="xl">Your library is empty</Text>
      <Text size="md" style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
        Save strings and you'll see them here!
      </Text>
      <Button onClick={handleCreateClick}>Start creating</Button>
    </Container>
  );
};

export default EmptyLibraryState;