import React from 'react';
import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from '@mantine/core';
import { IconBallpen, IconAdjustmentsHorizontal, IconClockHour8, IconGauge, IconUser, IconCookie, IconBooks, IconCircleCheck, IconRocket, IconBulb, IconGlobe  } from '@tabler/icons-react';
import useStyles from './css/LandingPageStyles';  // import here

const mockdata = [
  {
    title: 'Improve quality',
    description:
      "Great UX copy increases engagement and call-to-action conversion rates. Stringbot helps make sure that yours is the best it can be, whether you're an expert or amateur.",
    icon: IconBallpen,
  },
  {
    title: 'Save time',
    description:
      'Generate ideas based on messy thoughts to jumpstart your UX copy process.',
    icon: IconClockHour8,
  },
  {
    title: 'Drive brand consistency',
    description:
      'Customize the style and ensure your UX copy is in a consistent voice across your product. ',
    icon: IconAdjustmentsHorizontal,
  },
];

const LandingPageFeatures = () => {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="lg" className={classes.card} padding="xl">
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="xl" fw={700} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="md" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl" style={{ marginBottom: '1rem' }}>

      <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        {features}
      </SimpleGrid>
    </Container>
  );
};

export default LandingPageFeatures;
