import React from 'react';
import { List, ThemeIcon } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

function SignUpBenefitsList() {
  return (
    <List spacing="xs" icon={<ThemeIcon size={24} radius="xl"><IconCircleCheck size="1rem" /></ThemeIcon>}>
      <List.Item>Write your own string descriptions</List.Item>
      <List.Item>Save your strings and manage them in a library</List.Item>
      <List.Item>Tag your strings to organize them how you want (coming soon)</List.Item>
      <List.Item>Create saved voice personas (coming soon)</List.Item>
    </List>
  );
}

export default SignUpBenefitsList;