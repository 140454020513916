import React, { useState, useEffect } from 'react';
import { Textarea, ActionIcon } from '@mantine/core';
import { getHotkeyHandler, useDebouncedValue } from '@mantine/hooks';
import { IconArrowRight } from '@tabler/icons-react';

function ChatTextArea({
    sendMessage,
    loading,
    localInputValue,
    setLocalInputValue,
}) {
    
    const [chatLocalInputValue, setChatLocalInputValue] = useState(localInputValue);
    const [placeholder, setPlaceholder] = useState('Enter a description of the UX copy you want');

    const [debouncedInput] = useDebouncedValue(chatLocalInputValue, 100);
    
    useEffect(() => {
        setLocalInputValue(debouncedInput);
    }, [debouncedInput, setLocalInputValue]);
    
    const handleTextAreaChange = (e) => {
        setChatLocalInputValue(e.target.value);
    };

     // Placeholder switching logic
     useEffect(() => {
        const placeholders = [
            'Enter a description or an idea for the UX copy you want...',
            'E.g., "Your order is on its way"',
            'E.g., "Title for account sign up page, for a website that helps influencers manage followers"',
            'E.g., "A disclaimer that the text is AI generated and quality isn\'t guaranteed"',
            'E.g., "Button text for confirming payment details"',
        ];
        let index = 0;

        const interval = setInterval(() => {
            index = (index + 1) % placeholders.length;
            setPlaceholder(placeholders[index]);
        }, 5000);

        // Cleanup interval on component unmount
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Textarea  
            onKeyDown={getHotkeyHandler([
                ['Enter', sendMessage],
            ])}
            size="md"
            minRows={4}
            maxLength={500}
            radius="md"
            rightSectionWidth="60"
            value={chatLocalInputValue}
            onChange={handleTextAreaChange}
            placeholder={placeholder}
            style={{ marginBottom: '0.0rem' }}
            rightSection={
                <ActionIcon disabled={!localInputValue} radius="lg" onClick={sendMessage} loading={loading} loaderProps={{variant: "dots"}} variant="gradient">
                    <IconArrowRight/>
                </ActionIcon>
            }
        />
    );
}

export default ChatTextArea;
