import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Image, Stack, Modal, Global, MantineProvider, Button, Container, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import HeaderSimple from './HeaderSimple';
import HomePage from './HomePage';
import ChatComponent from './ChatComponent';
import UserButton from './UserButton';
import LibraryPage from './LibraryPage';
import AccountPage from './AccountPage';
import LoginPage from './LoginPage';
import { Notifications, notifications } from '@mantine/notifications';
import { IconCheck, IconLogout } from '@tabler/icons-react';
import LandingPage from './LandingPage';
import Footer from './Footer';
import {Footer2} from './Footer2';
import Confetti from "react-confetti";
import commonStyles from './css/CommonStyles.js';
import sfpro from './assets/sf-pro/SFPRODISPLAYREGULAR.OTF';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  const [string1, setString1] = useState('Write a string description in the box above and press the arrow button.');
  const [string1Score, setString1Score] = useState('100');
  const [string2, setString2] = useState('Ideas will show up in these boxes.');
  const [string2Score, setString2Score] = useState('100');
  const [string3, setString3] = useState('Then, make tweaks and save your favorites.');
  const [string3Score, setString3Score] = useState('100');
  //const [inputValue, setInputValue] = useState('A status message to let customers know their payment successfully went through.');
  const [inputValue, setInputValue] = useState('');

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false); // New state
  const [links, setLinks] = useState([]);
  const [activeLink, setActiveLink] = useState(''); // Set as empty initially
  const [showConfetti, setShowConfetti] = useState(false); // New state for confetti
  const [userName, setUserName] = useState(null);

  const { classes } = commonStyles();
  
  const isMobile = useMediaQuery('(max-width: 50em)');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUserName = localStorage.getItem('userName');

    if (token) {
      setIsUserLoggedIn(true);
      if (savedUserName) {
        setUserName(savedUserName);
      }
    } else {
      setIsUserLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn)
    {
      setLinks([
        { link: '/create', label: 'Create' },
        { link: '/library', label: 'Library' },
        { link: '/logout', label: 'Log out' },
      ]);
    }
    else {
      setLinks([
        { link: '/landing', label: 'Get started' },
        { link: '/login', label: 'Log in' },
        { link: '/account', label: 'Sign up' },
      ]);
    }
  }, [isUserLoggedIn]);


  // Define this function to reset the app's state to its default values
  const resetState = () => {
    setString1('Write a string description in the box above and press the arrow button.');
    setString2('Ideas will populate in these boxes.');
    setString3('Then, make tweaks and save your favorites.');
    setString3Score('100');
    setString2Score('100');
    setString1Score('100');
    setInputValue('');
    setIsUserLoggedIn(false);
    setUserName('');
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    resetState();

    notifications.show({
      title: `Logged out`,
      message: 'See you soon!',
      color: 'green',
      icon: <IconCheck size="1rem" />,
    })
  }, []);

  const handleJWTExpiry = () => {
    console.log("handleJWTExpiry");
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    resetState();

    notifications.show({
      title: `Session timed out`,
      message: 'Please log in again',
      color: 'green',
      icon: <IconLogout size="1rem" />,
    })
    
  }; 

  useEffect(() => {
    if (links.length > 0) {
      setActiveLink(links[0].link);
    }
  }, [links]);

  const renderPage = () => {
    // if (!isUserLoggedIn && activeLink === '#section1') {
    //  return <LandingPage />;
    //}
    switch (activeLink) {
      case '/landing':
        return <LandingPage setActiveLink={setActiveLink}/>;
      case '/demo':
        return <ChatComponent setActiveLink={setActiveLink} mode="demo"/>;
      case '/create':
        return <ChatComponent mode="create" inputValue={inputValue} setInputValue={setInputValue} string1={string1} string1Score={string1Score} setString1Score={setString1Score} string2={string2} string2Score={string2Score} setString2Score={setString2Score} string3={string3} setString3Score={setString3Score}string3Score={string3Score} setString1={setString1} setString2={setString2} setString3={setString3} setActiveLink={setActiveLink} handleJWTExpiry={handleJWTExpiry}/>;
      case '/library':
        return <LibraryPage setActiveLink={setActiveLink} handleJWTExpiry={handleJWTExpiry}/>;
      case '/login':
        return <LoginPage setActiveLink={setActiveLink} setIsUserLoggedIn={setIsUserLoggedIn} setUserName={setUserName}/>;  // new page
      case '/account':
        return <AccountPage setShowConfetti={setShowConfetti} setIsUserLoggedIn={setIsUserLoggedIn} setActiveLink={setActiveLink} setUserName={setUserName}/>;
      default:
        return null;
    }
  };

  return (
    <BrowserRouter>
    <MantineProvider
    theme={{
      primaryColor: 'orange',
      //headings: { fontFamily: 'Greycliff CF, sans-serif' },
      //fontFamily: {sfpro},
      defaultGradient: {
        from: 'orange',
        to: 'red',
        deg: 60,
      },
      //fontFamily: 'Verdana',
      
      //fontFamily: 'Verdana, sans-serif',
      //fontFamilyMonospace: 'Monaco, Courier, monospace',
      globalStyles: (theme) => ({
        primaryShade: { light: 8, dark: 8 },
        body: {
          //backgroundColor: theme.colors.gray[2],
          //backgroundColor: theme.colors.orange[0],
          //backgroundColor: "#ffedd6",
          //backgroundColor: "#fffcf7",
          backgroundColor: "#fcfcfa",
        }
      }),
    }}  
    >
      <div className={!isUserLoggedIn && (activeLink == '' || activeLink == '/landing') ? classes.landingPageContainer : classes.appContainer}>
        <Notifications/>
          <HeaderSimple
            links={links}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
            isUserLoggedIn={isUserLoggedIn}  // Pass state as prop
            handleLogout={handleLogout}  // Pass function as prop
            setIsUserLoggedIn={setIsUserLoggedIn}
            userName={userName}
          />
          <div className={classes.contentContainer}>
            <Container size="md" style={{ alignItems: 'left', textAlign: 'left', marginBottom: '2rem' }}>
            {/*<Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/library" element={<LibraryPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/create" element={<ChatComponent />} />
              {/* Add more routes as needed
            </Routes>*/}
              {renderPage()}
            </Container>
          </div>
        
          <Footer/>
          {showConfetti &&
            <Modal
              size="35%" 
              centered
              opened={true}
              onClose={() => {setShowConfetti(false)}}
              fullScreen={isMobile}
            >
              
                <Stack align="center">
                  <Image  
                    style={{
                      maxWidth: '150px',  // Limit the maximum width
                      marginTop: isMobile ? '5rem' : '0',
                    }}
                    radius="xl" src="yarn_misc.png">
                  </Image>
                  <Title order={2} fw={700} >Welcome to Stringbot!</Title>
                  <Text>
                    Let's get started. Enter UX copy descriptions in the box, press the arrow button, and start saving your favorite ideas.
                  </Text>
                  <Button onClick={() => {setShowConfetti(false)}}>Start creating</Button>
                  
                </Stack>
              
            </Modal>
          }
          
        {showConfetti && <Confetti recycle={false} />} {/* Render Confetti when showConfetti is true */}
      </div>
    </MantineProvider>
    </BrowserRouter>
  );
}

export default App;


