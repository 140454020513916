import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Center, Spoiler, Box, ScrollArea, Tabs, Space, rem, createStyles, Stack, Tooltip, Divider, Loader, Grid, ActionIcon, Paper, SimpleGrid, Textarea, Accordion, Title, Button, Container, Input, Text, Slider, Chip, Group } from '@mantine/core';
import api from './api';
import AvatarChip from './AvatarChip';
import IdeaPaper from './IdeaPaper';
import { IconAdjustmentsDown, IconChevronUp, IconChevronDown, IconMessageCircle, IconSettings, IconAdjustments, IconArrowRight, IconSend, IconCopy, IconDeviceFloppy, IconExclamationCircle, IconRuler2 } from '@tabler/icons-react';
import SignUpButton from './SignUpButton';
import SignUpBenefitsList from './SignUpBenefitsList';
import { useClickOutside, getHotkeyHandler, useDisclosure, useDebouncedValue  } from '@mantine/hooks';
import { Notifications, notifications } from '@mantine/notifications';
import commonStyles from './css/CommonStyles.js';
import ChatTextArea from './ChatTextArea.js';
import ReactGA from 'react-ga4';

function ChatComponent({ mode, setActiveLink, handleJWTExpiry, inputValue, setInputValue, string1, string2, string3, string1Score, string2Score, string3Score, setString1Score, setString2Score, setString3Score, setString1, setString2, setString3 }) {
  // A status message to let customers know their payment successfully went through.
  const [sliderValue, setSliderValue] = useState(4);
  const [toneValue, setToneValue] = useState('objective');
  const [selectedTone, setSelectedTone] = useState('1');
  const [lengthValue, setLengthValue] = useState('1 sentence');
  const [locationValue, setLocationValue] = useState('General');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
      sendMessage();
  }, [toneValue]);

  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const [userPrompt, setUserPrompt] = useState('None');
  const READING_LEVELS = [
    { value: 1, label: 'Kindergarten' },
    { value: 2, label: '2nd grade' },
    { value: 3, label: '4th grade' },
    { value: 4, label: '6th grade' },
    { value: 5, label: '8th grade' },
    { value: 6, label: 'High school' },
    { value: 7, label: 'University' },
    { value: 8, label: 'Ph. D' },
  ];
  const [isSending, setIsSending] = useState(false);
  const [localInputValue, setLocalInputValue] = useState(inputValue);
  
  //update the parent value every 2 seconds second, but no more. 
  const [debouncedInput] = useDebouncedValue(localInputValue, 2000);
  useEffect(() => {
      setInputValue(debouncedInput);
  }, [debouncedInput, setInputValue]);


  function tokenizeResponse(response) {
    
    const listRegex = /\d+[.)]\s+/g;  // Adjust regex to capture both "1. " and "1) " patterns
    
    // Remove text between numbered lists.
    response = response.replace(/(\d+[.)].*?)(?=\n\s*\d+[.)])/gs, '$1');
    
    const listItems = response.split(listRegex).filter(Boolean).map((item) => item.replace(/["]/g, ''));
    const numberedLists = response.match(listRegex) || [];

    return {
      listItems,
      numberedLists,
    };
  }

  // Utility function to keep only numeric characters (including decimals)
  const extractNumber = (str) => {
    const result = str.match(/[\d.]+/); // Match numbers and decimals
    return result ? result[0] : '';     // Return the matched number or an empty string
  }

  function toggleVisibility(divId) {
    const div = document.getElementById(divId);
    if (div.style.display === 'none') {
      div.style.display = 'block';
    } else {
      //div.style.display = 'none';
    }
  }

  const handleSignUpClick = () => {
    setActiveLink('/account'); 
  };

  const sendMessage = async () => {
    if (isSending || !localInputValue) {
      return;
    }
    // Set the flag to true, blocking further messages
    setIsSending(true);

    // Close accordion
    setOpenAccordion(null);

    console.log('CREATE TONE:', toneValue);
    
    try {
      setLoading(true);

      const message = `You are a UX content designer/writer and we need your help with UX copy. Here are the content guidelines:
      - The brand voice should be clear, concise and human, like a friendly, intelligent coworker
      - Target an American audience
      - Make copy concise
      - Use an active voice
      - Avoid using please
      - Don't use exclamation marks
      - Use sentence case
      - Use Associated Press style
      - It should be written in a ${toneValue} tone.
      - It should be written at a ${READING_LEVELS.find((mark) => mark.value === sliderValue)?.label} reading level.
      - Its length MUST be ${lengthValue}, NOT any longer or shorter.
      
      The UX copy description from the user is in the user message. 
      
      Please present ideas in a numbered list (e.g., 1. item1 2. item2 3. item3) with no preamble text before or after. Only list the top three ideas that are easiest to read. Do not include a description of the strings. Then, provide a readability score based on the Flesch Kincaid Reasing Ease model on a scale of 1-100 for each string on the next 3 lines (e.g., 4. 100, 5. 82, 6. 30)`;
      
      // I removed UI location to simplify - to add back - In the UI, it will be a ${locationValue}.

       const requestPayload = {
        model: 'gpt-4-1106-preview',
        messages: [
          { role: 'system', content: message },
          { role: 'user', content: localInputValue },
        ],
      };

      setUserPrompt(localInputValue); // Store the user's prompt

      //console.log('Chat comp API Request:', requestPayload);

      const response = await api.postToGenerateText(requestPayload);
      
      //console.log('Chat comp API Response:', response);

      if(response){
          // Do something
        
        //console.log('API Response:', response);

        const { listItems, numberedLists } = tokenizeResponse(response);

        //console.log('List Items:', listItems);
        //console.log('Numbered Lists:', numberedLists);
        setIsPlaceholder(false);
        setString1(listItems[0] || 'Could not generate an idea at the moment, please try again.');
        setString2(listItems[1] || 'Could not generate an idea at the moment, please try again.');
        setString3(listItems[2] || 'Could not generate an idea at the moment, please try again.');
        // Extract numeric value for the scores and set them
        setString1Score(extractNumber(listItems[3] || ''));
        setString2Score(extractNumber(listItems[4] || ''));
        setString3Score(extractNumber(listItems[5] || ''));
        //toggleVisibility('idea-grid')

        ReactGA.event({
          category: 'Create',
          action: 'Create: string created.'
        });

      } else {
        throw new Error('No valid content in the response');
      }
      
    } catch (error) {
        if(error.response && error.response.data){
          console.error('API Error:', error.response.data.error);
        } else {
          console.error('API Error:', error);
        }
        notifications.show({
          title: `Something went wrong`,
          message: "Make sure you're connected to the internet.",
          color: 'red',
          icon: <IconExclamationCircle size="1rem" />,
        })
    } finally {
      setLoading(false);
    }
    // Clear the flag after 100ms second, allowing new messages to be sent
    setTimeout(() => setIsSending(false), 100);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleTextAreaChange = useCallback((e) => {
    setLocalInputValue(e.target.value);
  }, []);


  const { classes } = commonStyles();
  const iconStyle = { width: rem(12), height: rem(12) };

  return (
      <
      >
       <ChatTextArea
          sendMessage={sendMessage}
          loading={loading}
          localInputValue={localInputValue}
          setLocalInputValue={setLocalInputValue}
      />

      <Accordion  
        //variant="subtle" 
        value={openAccordion}
        onChange={(value) => setOpenAccordion(value)}
        defaultValue="customization" 
        variant="light"
        chevron={
          <ActionIcon radius="lg" color="orange" variant="outline">
              <IconAdjustmentsDown size="1.1rem"/>
          </ActionIcon>
        }
        style={{ padding: '0 !important', margin: '0 !important'}}
      >
          <Accordion.Item value="none">
          
              <Accordion.Control /*icon={<IconAdjustments size={rem(20)} color="gray" />}*/>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  
                  <ScrollArea w="100%" scrollHideDelay={0} scrollbarSize={0}>
                    {/* Need 730 for mobile */}
                    <Box w={870}>
                      {/*<Chip.Group defaultValue="4" >
                        <Group>
                          <Chip value="4" onClick={() => setToneValue('Objective')}>Objective</Chip>
                          <Chip value="3" onClick={() => setToneValue('formal')}>Formal</Chip>
                          <Chip value="5" onClick={() => setToneValue('whimsical')}>Whimsical</Chip>
                          <Chip value="6" onClick={() => setToneValue('conversational')}>Conversational</Chip>
                          <Chip value="7" onClick={() => setToneValue('persuasive')}>Persuasive</Chip>
                          <Chip value="8" onClick={() => setToneValue('blunt')}>Blunt</Chip>
                          <Chip value="9" onClick={() => setToneValue('Shakespeare')}>Shakespeare</Chip>
                          <Chip disabled value="10" onClick={() => setToneValue('...ignore...')}>Custom</Chip>
                        </Group>
                        </Chip.Group>*/}

                        {/* AvatarChips */}
                        <div>
                            <Group grow>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="1" selected={selectedTone} setSelectedTone={setSelectedTone} image="target.svg" label="Objective" tooltip="When providing facts, giving instructions or when neutrality is key (e.g., something goes wrong)."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="4" selected={selectedTone} setSelectedTone={setSelectedTone} image="talking.svg" label="Conversational" tooltip="When you want a friendly, approachable feel – in the onboarding process or general product use."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="3" selected={selectedTone} setSelectedTone={setSelectedTone} image="laugh.svg" label="Whimsical" tooltip="In light-hearted contexts, to spark joy, or when the brand is playful by nature."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="2" selected={selectedTone} setSelectedTone={setSelectedTone} image="businessman.svg" label="Formal" tooltip="When your audience expects professionalism, like in banking, legal, or medical fields."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="5" selected={selectedTone} setSelectedTone={setSelectedTone} image="rising.svg" label="Persuasive" tooltip="When looking to guide choices, like user sign up flows or highlighting product perks."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="6" selected={selectedTone} setSelectedTone={setSelectedTone} image="wood.svg" label="Blunt" tooltip="Reserve for times when attention is needed, such critical security or error alerts."></AvatarChip>
  
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="7" selected={selectedTone} setSelectedTone={setSelectedTone} image="shakespeare.svg" label="Shakespeare" tooltip="This one is just for fun, we don't recommend using Elizabethan English to communicate with users."></AvatarChip>
                              <AvatarChip isSending={isSending} setToneValue={setToneValue} value="8" selected={selectedTone} setSelectedTone={setSelectedTone} image="caveman.svg" label="Caveman" tooltip="This one is just for fun, we don't recommend using caveman speak to communicate with users."></AvatarChip>
                            </Group>
                        </div>    
                      
                    </Box>
                  </ScrollArea>
                </div>
              </Accordion.Control>

            <Accordion.Panel>
             <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', marginLeft: '0.25rem' }}>
      
                  <Text size='md' style={{ marginRight: '0.75rem', flexShrink: 0 }}>
                    Length
                  </Text>
                  <ScrollArea w="100%" scrollHideDelay={0} scrollbarSize={0}>
                    <Box w={680}>
                            <Chip.Group defaultValue="2">
                              <Group position="left">
                                <Chip value="2" onClick={() => setLengthValue('A few words')}>A few words</Chip>  
                                <Chip value="3" onClick={() => setLengthValue('1 sentence')}>1 sentence</Chip>
                                <Chip value="1" onClick={() => setLengthValue('1 word')}>1 word</Chip>
                                <Chip value="4" onClick={() => setLengthValue('2-3 sentences')}>2-3 sentences</Chip>
                                <Chip value="5" onClick={() => setLengthValue('Any length')}>Any length</Chip>
                              </Group>
                            </Chip.Group>
                    </Box>
                  </ScrollArea>
              </div>

                <div style={{ marginTop: '1.25rem', marginRight: '1rem', marginLeft: '0.25rem' }}>
                  <Grid>
                    <Grid.Col xs={5} sm={4} md={3.5} lg={3}>
                      <Text size='md'>
                        Reading level: 
                        <a className={classes.dimmedText}>{' ' + READING_LEVELS.find((mark) => mark.value === sliderValue)?.label}</a>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <Slider
                        label={(val) => READING_LEVELS.find((mark) => mark.value === val).label}
                        min={1}
                        max={8}
                        step={1}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        marks={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, , { value: 6 }, , { value: 7 }, , { value: 8 }]}
                        style={{ marginTop: '0.4rem', marginRight: '1rem' }}
                      />
                    </Grid.Col>
                  </Grid>
                </div>
            </Accordion.Panel>
          </Accordion.Item>
      </Accordion>

      
      

      
        {/*<div id='idea-grid' style={{ display: 'none' }}>
        Consider going back to simplegrid to show horizontally on desktop. 
        */}
        <div style={{
            wordWrap: 'break-word',
          }}>
        <Stack cols={3} style={{ marginBottom: '1rem' }}>
            <IdeaPaper userPrompt={userPrompt} isPlaceholder={isPlaceholder} mode={mode} setActiveLink={setActiveLink} string={string1} setString={setString1} stringScore={string1Score} title="Idea #1" handleJWTExpiry={handleJWTExpiry}></IdeaPaper>
            <IdeaPaper userPrompt={userPrompt} isPlaceholder={isPlaceholder} mode={mode} setActiveLink={setActiveLink} string={string2} setString={setString2} stringScore={string2Score} title="Idea #2" handleJWTExpiry={handleJWTExpiry}></IdeaPaper>
            <IdeaPaper userPrompt={userPrompt} isPlaceholder={isPlaceholder} mode={mode} setActiveLink={setActiveLink} string={string3} setString={setString3} stringScore={string3Score} title="Idea #3" handleJWTExpiry={handleJWTExpiry}></IdeaPaper>
        </Stack>
        </div>
        {/*</div>
      
      This is where Accordion was*/}

        
      {/* If in demo mode, show a message that says an account is required to make your own description and a sign up button
      {mode === "demo" && (
        <div style={{ marginBottom: '1rem', marginTop: '3rem' }}>
          
          <Accordion defaultValue="benefits" transitionDuration={300}>
            <Accordion.Item value="benefits">
              <Accordion.Control>Discover the benefits of StringBot</Accordion.Control>
              <Accordion.Panel>
                 <SignUpBenefitsList/>
                 <SignUpButton size="sm" text="Start brainstorming" handleSignUpClick={handleSignUpClick} style={{marginTop: '5rem', marginBottom: '2rem'}} />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      */}

{/*
      <Accordion  
      //variant="subtle" 
      value={openAccordion}
      onChange={(value) => setOpenAccordion(value)}
      defaultValue="customization" 
      styles={{ marginBottom: '1rem' }}
      classNames={{
        panel: classes.panel,
        control: classes.control,
      }
    >
      
            <Accordion.Item value="none">
              <Accordion.Control icon={<IconAdjustments size={rem(20)} color="gray" />}>Style</Accordion.Control>
              <Accordion.Panel>
                <Divider my="sm" color="orange" variant="dotted"/>
                
                <Divider my="sm" color="orange" variant="dotted" />
                <Text style={{ marginBottom: '1rem' }}>
                  Type
                </Text>
                <div style={{ marginBottom: '1rem', marginRight: '1rem', marginLeft: '1rem' }}>
                  <Chip.Group defaultValue="7">
                    <Group position="left">
                      <Chip value="1" onClick={() => setLocationValue('status message')}>Status message</Chip>
                      <Chip value="2" onClick={() => setLocationValue('button text')}>Button text</Chip>
                      <Chip value="3" onClick={() => setLocationValue('tooltip')}>Tooltip</Chip>
                      <Chip value="4" onClick={() => setLocationValue('call to action')}>Call to action</Chip>
                      <Chip value="5" onClick={() => setLocationValue('description')}>Description</Chip>
                      <Chip value="6" onClick={() => setLocationValue('title')}>Title</Chip>
                      <Chip value="7" onClick={() => setLocationValue('general messaging')}>General</Chip>
                    </Group>
                  </Chip.Group>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="none2">
              <Accordion.Control icon={<IconRuler2 size={rem(20)} color="gray" />}>
                Length
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ marginBottom: '1rem' }}>
                  <Chip.Group defaultValue="3">
                    <Group position="left">
                      <Chip value="1" onClick={() => setLengthValue('1 word')}>1 word</Chip>
                      <Chip value="2" onClick={() => setLengthValue('A few words')}>A few words</Chip>
                      <Chip value="3" onClick={() => setLengthValue('1 sentence')}>1 sentence</Chip>
                      <Chip value="4" onClick={() => setLengthValue('2-3 sentences')}>2-3 sentences</Chip>
                      <Chip value="5" onClick={() => setLengthValue('Any length')}>Any length</Chip>
                    </Group>
                  </Chip.Group>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
    </Accordion>*/}
          
      </>
  );
}

export default React.memo(ChatComponent);
