const login = (email, password) => {
    const userData = {
      email,
      password,
    };
  
    return fetch('https://stringbotwebapp.azurewebsites.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then((response) => response.json());
  };
  
  export default login;
  