import axios from 'axios';

const api = axios.create({
  baseURL: 'https://stringbotwebapp.azurewebsites.net', // Replace with the base URL of your backend
  headers: {
    'Content-Type': 'application/json',
    // Setting the default Authorization header for all requests made by this axios instance
  }
});

// Using an interceptor to set the Authorization header before each request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers['Content-Type'] = 'application/json';
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


const postToGenerateText = async (payload) => {
  try {
    const response = await api.post('/generate-text', payload);
    
    
    const generatedText = response.data.choices?.[0]?.message?.content?.trim();
    if (generatedText) {
      return generatedText;
    } else {
      throw new Error("No text was generated.");
    }
  } catch (error) {
    console.error("Error while generating text:", error);
    throw error;
  }
};

export default {
  postToGenerateText
};
