import React, { useState } from 'react';
import { Stack, Alert, Container, TextInput, Button, Paper, Title, Text } from '@mantine/core';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useClickOutside, getHotkeyHandler } from '@mantine/hooks';
import login from './login'; // Import the login function from api.js
import ReactGA from 'react-ga4';

function LoginPage({ setIsUserLoggedIn, setUserName, setActiveLink }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({ success: null, message: '' });

  const handleLoginClick = () => {
    setActiveLink('/account'); 
  };

  const handleLogin = () => {
    setLoading(true);

    login(email, password) // Use the login function here
      .then((data) => {
        console.log(data);

        // If login is successful, store the JWT in localStorage.
        if (data.success) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('userName', data.name);
          setIsUserLoggedIn(true); // Update state here
          setUserName(data.name); // Store the user's name in state
          console.log('Logging in user... ', data.name);

          // Show notification
          notifications.show({
            title: `Welcome, ${data.name}!`,
            message: 'You\'re logged in.',
            color: 'green',
            icon: <IconCheck size="1rem" />,
          });
        }

        ReactGA.event({
          category: 'Login',
          action: `Login: Success  ${data.name}`  
        });

        // Use the success and message properties from the server's response.
        setResponse({ success: data.success, message: data.message });
      })
      .catch((error) => {
        console.error('Error logging in:', error);
        // If there's an error with the request (like a network error), show a generic error message.
        setResponse({ success: false, message: 'Something went wrong. Make sure you’re connected to the internet' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container 
      size="xs" 
      style={{ paddingTop: '3rem' }}
      onKeyDown={getHotkeyHandler([
          ['Enter', handleLogin],
      ])}
      >
      
      <Paper 
          shadow="xs" radius="lg" 
          p="lg" withBorder 
          style={{ marginBottom: '2rem', marginTop: '2rem' }}
      >
        <Title order={2} color="dimmed" align="center" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              Welcome back
        </Title>
        <Stack>
          <TextInput
            type="email"
            value={email}
            size="md"
            onChange={(event) => setEmail(event.currentTarget.value)}
            placeholder="Email"
            required
            style={{ marginBottom: '0.5rem', marginTop: '1rem' }}
            autoFocus 
          />
          <TextInput
            type="password"
            value={password}
            size="md"
            onChange={(event) => setPassword(event.currentTarget.value)}
            placeholder="Password"
            required
            style={{ marginBottom: '1.5rem' }}
          />
            <Button onClick={handleLogin} loading={loading}>
              Log in
            </Button>
          {response.message && (
            <Alert
              title={response.message}
              color={response.success ? 'green' : 'red'}
              icon={response.success ? <IconCheck size="1rem" /> : <IconAlertCircle size="1rem" />}
              shadow="xs"
              style={{ marginTop: '1rem' }}
            >
            </Alert>
            
          )}
          <Text align="center" style={{ marginTop: '1.5rem' }}>
            Don't have an account? <a href="#" onClick={handleLoginClick}> Sign up</a>
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
}

export default LoginPage;
