import React from 'react';
import { Blockquote, Avatar, Text, Container, Button, Paper, Card, Rating, Center } from '@mantine/core';
import useStyles from './css/LandingPageStyles';  // import here
import { createStyles, rem } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconQuote } from '@tabler/icons-react';

function TestimonialBlurb({ avatar, name, email, job }) {
    const { classes, theme } = useStyles();
    const { hovered, ref } = useHover();
  return (

    <Card
        radius="lg"
        withBorder
        padding="xl"
        className={classes.card}
        shadow="md"
        ref={ref}
        >
        <Avatar src={avatar} size={hovered ? 123 : 120} radius={120} mx="auto" />

        {/*<Center style={{ marginTop: '1rem' }} >
            <Rating defaultValue={5} readOnly="true" />
          </Center>
          */}
        <Text align="center" fz="lg" fontWeight={500} mt="md">
            {'"' + name + '"'}
        </Text>
       
        <Text align="center" color="dimmed" fontSize="sm">
            {email} • {job}
        </Text>

    </Card>
  );
}

export default TestimonialBlurb;
