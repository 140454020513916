import React, { forwardRef, useState, useEffect } from 'react';
import { CloseButton, Box, Transition, Grid, Space, Accordion, Badge, MultiSelect, createStyles, ThemeIcon, List, Stack, Modal, Button, FocusTrap, Tooltip, ActionIcon, Group, Title, Avatar, Paper, Text, Container, Textarea } from '@mantine/core';
import { IconCheck, IconTag, IconEdit, IconCopy, IconDeviceFloppy, IconCirclePlus, IconCircleCheck, IconTrash, IconInfoCircle } from '@tabler/icons-react';
import { useClickOutside, getHotkeyHandler, useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import IdeaCopyButton from './IdeaCopyButton';
import SaveButton from './SaveButton';
import SignUpBenefitsList from './SignUpBenefitsList';
import commonStyles from './css/CommonStyles.js';

  
function IdeaPaper({ title, string, setString, isUserLoggedIn, mode, _id , setActiveLink, onDelete, isPlaceholder, updateString, tagData, setTagData, userPrompt, allTags, handleJWTExpiry, stringScore }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedString, setEditedString] = useState(string);
    const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
    const [demoModalOpened, { open: openDemoModal, close: closeDemoModal }] = useDisclosure(false);
    // only for creation
    const [saveStatus, setSaveStatus] = useState("unsaved"); // "unsaved", "saving", "saved", "error"
    // only for library mode
    const [editSaved, setEditSaved] = useState(false);


    const handleCreateTag = async (query) => {
        const newTag = { tag: query };
        
        try {
            const response = await fetch('https://stringbotwebapp.azurewebsites.net/tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(newTag)
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('JWT expired');
                }
                throw new Error('Error saving new tag');
            }
    
            const data = await response.json();
            
            console.log('New tags!:', data.tag);
            console.log('New tags2!:', newTag);

            // Update local state with the saved tag (might include additional fields from server)
           let updatedTags = [...tagData, data.tag || newTag];
           console.log('New tags3!:', updatedTags);
           updateString(editedString, updatedTags, _id);
            
            return { value: newTag, label: newTag };
    
        } catch (error) {
            if (error.message === 'JWT expired') {
                handleJWTExpiry();
            } else {
                 console.error('Error saving new tag:', error);
                // Optionally handle error (e.g., display notification to user)
            }
        }
    }
    
    

    const handleSignUpClick = () => {
        setActiveLink('/account'); 
    };
    

    async function handleStopEdit(tags) {
        setIsEditing(false);
        setEditedString(editedString); 

        //Also set baseline string so create mode can keep context
        if (setString) {
             setString(editedString);
        }

        console.log('Pre save tags:', tags);

        let updatedTags = tags;

        if (tags instanceof KeyboardEvent) {
            // Early return if it's a keyboard event, so it won't get processed as a tag
            updatedTags = tagData;
        }

        if (mode === "library") {
            const result = await saveEditedString(editedString, _id, updatedTags); // Pass tag if you have a way to get it

            if (result) {
                // Handle success case, show a notification, etc.
                console.log('String updated successfully:', result);
                // Call the updateString callback to update the strings state in LibraryPage
                updateString(editedString, updatedTags, _id);
            } else {
                // Handle error case, show an error notification, etc.
                console.error('Failed to update string: notify');
                notifications.show({
                    title: `Changes weren't saved`,
                    message: 'Something went wrong, please try again.',
                    color: 'red',
                    icon: <IconEdit size="1rem" />,
                  })
            }
        }
    };

    // Integrate with the readable.com api to check for letter grade of string
    // https://readable.com/api/
    // https://readable.com/api/docs
    // commenting out until I can test. 
    /*const getReadability = async (string) => {
        const apiKey = 'YOUR_READABLE_API_KEY'; // Replace with your Readable.com API key
      
        try {
          // Make a GET request to the Readable.com API
          const response = await fetch(`https://api.readable.com/api/text/1.0/measure?text=${encodeURIComponent(string)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': apiKey,
            },
          });
      
          if (!response.ok) {
            throw new Error('Unable to fetch data from Readable.com API');
          }
      
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching readability data:', error);
          return null;
        }
      };*/


    const ref = useClickOutside(handleStopEdit);

    useEffect(() => {
        if (isEditing) {
            //set keyboard focus when someone clicks edit
            ref.current.focus();
            //set it to the end of the box
            const len = ref.current.value.length;
            ref.current.selectionStart = len;
            ref.current.selectionEnd = len;
        }
    }, [isEditing]);

    //db save code
    const handleSaveClick = async (e) => {
        setIsEditing(false);
        await setEditedString(editedString);
        setSaveStatus("saving");
        console.log("handleSaveClick: saving");
        try {
            const result = await fetch(
                'https://stringbotwebapp.azurewebsites.net/savestring', {
                method: "post",
                body: JSON.stringify({ string: editedString, userPrompt: userPrompt }),
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (!result.ok) {
                if (result.status === 401) {
                    throw new Error('JWT expired');
                }
                throw new Error('Error saving string');
            }

            let resultJson = await result.json();
        
            console.warn(resultJson);
            setSaveStatus("saved");
            console.log("handleSaveClick: saved");

            if (resultJson) {
                //alert
            }
        } catch (error) {
            console.error('Error saving string:', error);
            setSaveStatus("error");
            console.log("handleSaveClick: error");
            console.log('Bababooy');
            if (error.message === 'JWT expired') {
                handleJWTExpiry();
            } 
        }
    }

    async function saveEditedString(editedString, _id, tags) {
        try {
          const response = await fetch(`https://stringbotwebapp.azurewebsites.net/strings/${_id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ string: editedString, tags: tags }),
          });
      
          if (!response.ok) {
            if (response.status === 401) {
                throw new Error('Token expired');
            }
            throw new Error('Error updating string');
          }
      
          const data = await response.json();
          setEditSaved(true);
          setTimeout(() => setEditSaved(false), 2000);

          return data;
        } catch (error) {
            if (error.message === 'Token expired') {
                handleJWTExpiry();
            }
          console.error('Error updating string:', error);
          return null;
        }
      }

    const handleDeleteString = async () => {
        try {
            const response = await fetch(`https://stringbotwebapp.azurewebsites.net/strings/${_id}`, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
          });
    
          if (response.status === 401) {
            throw new Error('Token expired');
          }

          if (!response.ok) {
                throw new Error('Error deleting string');
          }

          console.log('String deleted successfully!');
          onDelete(_id); // Call the onDelete prop with the deleted string's _id
            notifications.show({
                title: `String deleted`,
                color: 'green',
                icon: <IconTrash size="1rem" />,
            })
        } catch (error) {
            console.error('Error deleting string:', error);
            if (error.message === 'Token expired') {
                handleJWTExpiry();
            }
            else {
                notifications.show({
                    title: `String wasn't deleted`,
                    message: 'Something went wrong, please try again.',
                    color: 'red',
                    icon: <IconTrash size="1rem" />,
                })
            }
          // Handle error case
            
        }
        closeDeleteModal();
    };



    useEffect(() => {
        setEditedString(string);
        setIsEditing(false);
    }, [string]);

    const handleEditClick = () => {
        setEditedString(editedString.trim());
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setEditedString(e.target.value);
    };

    const { classes } = commonStyles();

    const getBadgeColor = (score) => {
        if (score > 80) return "green";
        if (score > 60) return "yellow";
        return "red";
    }

    const getBadgeText = (score) => {
        if (score > 80) return "Good";
        if (score > 60) return "Fair";
        return "Poor";
    }


    function MultiSelectValue({ value, label, onRemove, classNames, ...others }) {
        return (
          <div {...others}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                cursor: 'default',
                alignItems: 'center',
                border: `1px solid ${theme.colors.orange[5]}`,
                backgroundColor: `${theme.colors.orange[1]}`,
                paddingLeft: 10,
                borderRadius: 4,
              })}
            >
              <div style={{ lineHeight: 1, fontSize: 12 }}>{label}</div>
              <CloseButton onMouseDown={onRemove} variant="transparent" size={22} iconSize={14} tabIndex={-1} />
            </Box>
          </div>
        );
      }

    const MultiSelectItem = forwardRef(({ label, value, ...others }, ref) => (
        <div ref={ref} {...others}>
          <Box sx={{ display: 'flex' }}>
            <Box mr={10}>
              <IconTag color="gray"/>
            </Box>
            <div>{label}</div>
          </Box>
        </div>
      ));

    return (
        <>
        <Paper withBorder radius="md" shadow="sm" p={mode === "library" ? "xs" : "md"}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            onKeyDown={getHotkeyHandler([
                ['Enter', handleStopEdit],
            ])}
            className={classes.paperColor}
        //tabIndex={0} // Ensure the container is focusable
        >
            {isEditing ? (
                <Textarea
                    size="md"
                    autosize maxLength="400" maxRows={10} value={editedString} onChange={handleInputChange}
                    ref={ref}
                />
            ) : (mode !== "library") ? (
                <Text
                    //fs={isPlaceholder ? "italic" : ''} // Apply italic font style if isPlaceHolder is true
                    // c={isPlaceholder ? "gray.5" : ''} // Apply gray color if isPlaceHolder is true 
                    style={{ marginBottom: '1rem' }}
                    size="md"
                >
                    {editedString}
                </Text>
            ) : (
                <Accordion variant="light"

                >
                    <Accordion.Item value="details">
                        <Accordion.Control>
                            <Group>

                            <Text
                                //fs={isPlaceholder ? "italic" : ''} // Apply italic font style if isPlaceHolder is true
                                // c={isPlaceholder ? "gray.5" : ''} // Apply gray color if isPlaceHolder is true 
                                style={{ marginBottom: '1rem' }}
                            >
                                {editedString}
                            </Text>
                            </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                        <Text fz="sm" >
                            Tags
                        </Text>
                        <MultiSelect style={{ marginBottom: '1rem' }} 
                            size="md"
                            data={allTags}
                            defaultValue={tagData}
                            itemComponent={MultiSelectItem}
                            valueComponent={MultiSelectValue}
                            placeholder={"Add tags"}
                            searchable
                            creatable
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onChange={(selectedTags) => {
                                handleStopEdit(selectedTags); // Pass the selected tag values to the backend
                            }}
                            onCreate={(query) => {
                                const item = { value: query, label: query };
                                handleCreateTag(query);
                                setTagData((current) => [...current, item]);
                                return item;
                            }}
                           
                        />
                        <Text fz="sm" >
                            Description
                        </Text>
                        <Text  fz="sm" color="dimmed" style={{ marginTop: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                            {userPrompt}
                        </Text>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            )}
            <div style={{ marginTop: 'auto' }}>
            <Grid>
            <Grid.Col span={8}>
                <Group spacing={5}>
                    {isEditing ? (
                        <>
                            {/*<ActionIcon onClick={handleSaveClick}>
                                <IconDeviceFloppy size="1rem" />
                            </ActionIcon>*/}
                        </>
                    ) : (
                        <>
                            {mode === "library" ? (<Space w="xs" />) : ''}
                            <IdeaCopyButton
                                copyText={editedString}
                                 
                            />
                            <Tooltip label="Edit" withArrow position="right">
                                <ActionIcon onClick={handleEditClick}>
                                    <IconEdit size="1rem" className={classes.buttonColor} />
                                </ActionIcon>
                            </Tooltip>
                            {mode !== "library" ? (
                                <SaveButton mode={mode} onClick={mode === "demo" ? openDemoModal : handleSaveClick} timeout={1500} saveStatus={saveStatus} setSaveStatus={setSaveStatus}/>
                            ) : (
                                <>
                                    <Tooltip label="Delete" withArrow position="right">
                                        <ActionIcon onClick={openDeleteModal}>
                                            <IconTrash size="1rem" className={classes.buttonColor}/>
                                        </ActionIcon>
                                    </Tooltip>
                                </>
                            )
                            }
                        </>
                    )}
                </Group>
            </Grid.Col>
            <Grid.Col span={2} offset={2}>
                <Transition mounted={editSaved} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => (
                        <div style={{ ...styles, textAlign: 'right' }}>
                            <Tooltip label="Changes saved" withArrow position="right">
                                <IconCheck size="1rem" color="green" style={{ marginRight: '1.25rem' }}/>
                            </Tooltip>
                        </div>
                    )}
                </Transition>
            {mode === "create" && (
                    <div style={{  textAlign: 'right' }}>
                        <Tooltip label="Readability" withArrow position="right">
                            <Badge color={getBadgeColor(stringScore)}>{getBadgeText(stringScore)}</Badge>
                        </Tooltip>
                    </div>

            )}
            </Grid.Col>
            </Grid>
                
            </div>
        </Paper>
        <Modal 
            size="55%" 
            opened={deleteModalOpened} 
            onClose={closeDeleteModal} 
            title="Are you sure you want to delete this string?" 
            centered
        >
            <Stack>
                <Button 
                    style={{ marginTop: '1rem' }}
                    data-autofocus
                    onClick={handleDeleteString}
                    onKeyDown={getHotkeyHandler([
                        ['Enter', handleDeleteString],
                    ])}
                >
                    Delete
                </Button>
            </Stack>
        </Modal>

        {mode === "demo" && (
            <Modal opened={demoModalOpened} onClose={closeDemoModal} title="Start saving and organizing ideas hassle-free by creating an account!" centered>
                <SignUpBenefitsList />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    centered
                    style={{ marginTop: '1rem', marginLeft: '0.5rem' }}
                    size="lg"
                    variant="gradient"
                    radius="xl"
                    onClick={handleSignUpClick}
                >
                    Sign up
                </Button>
                </div>
            </Modal>
        )}

        </>
    );
}

export default React.memo(IdeaPaper);
