import { CopyButton, ActionIcon, Tooltip } from '@mantine/core';
import { IconCopy, IconCheck } from '@tabler/icons-react';
import commonStyles from './css/CommonStyles.js';

function IdeaCopyButton({ copyText }) {
  const { classes } = commonStyles();

  return (
    <CopyButton value={copyText} timeout={1500}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
          <ActionIcon  color={copied ? 'teal' : 'gray'} onClick={copy}>
            {copied ? <IconCheck className={classes.buttonColor} size="1rem" /> : <IconCopy className={classes.buttonColor} size="1rem" />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButton>
  );
}

export default IdeaCopyButton;